<template>
  <z-container-table>
    <template #header>
      <el-form inline :model="queryParams" label-position="left">
        <el-form-item>
          <el-input v-model="queryParams.search" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <z-dept v-model="queryParams.dept_belong_id"></z-dept>
        </el-form-item>
        <el-form-item>
          <el-select v-model="queryParams.show" clearable style="width: 80px;">
            <el-option :value="true" label="显示"></el-option>
            <el-option :value="false" label="隐藏"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button icon="Search" type="primary" @click="MixinFilter"></el-button>
        </el-form-item>

        <el-form-item>
          <el-button v-show="hasPermission(this.$route.name,'Create')" @click="$refs.refEdit.show({},{onSubmit:MixinRowCreate})">添加</el-button>
        </el-form-item>
      </el-form>
    </template>
    <template #table>
      <vxe-grid v-bind="zGridConfig" ref="zGrid">
        <template #img="{ row }">
          <el-popover :hide-after="100" placement="left" :width="100" trigger="hover">
            <template #reference>
              <img :src="row.img" style="height: 30px;width:auto;">
            </template>
            <template #default>
              <el-image :src="row.img"></el-image>
            </template>
          </el-popover>
        </template>
        <template #dept="{ row }">
          <z-dept :text="true" v-model="row.dept_belong_id"></z-dept>
        </template>
        <template #show="{ row }">
          <template v-if="hasPermission(this.$route.name,'Status')">
            <el-switch @change="MixinRowSave(row)" v-model="row.show" inline-prompt style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" active-text="显示" inactive-text="隐藏"></el-switch>
          </template>
          <template v-else>
            <el-tag type="success" v-if="row.show">显示</el-tag>
            <el-tag type="info" v-else>隐藏</el-tag>
          </template>
        </template>
        <template #qrcode="{ row }">
          <div style="text-align: center">
            <el-image style="height: 20px;" :src="row.qrcode" :preview-src-list="[row.qrcode]"></el-image>
          </div>
        </template>
        <template #operate="{ row }">
          <el-button type="primary" link :loading="row.loading" @click="$refs.refEdit.show(row,{onChange:MixinRowSave})">编辑</el-button>
          <template v-if="hasPermission(this.$route.name, 'Delete')">
            <el-popconfirm confirm-button-text="是" cancel-button-text="否" title="是否删除?" :hide-after="0" @confirm="MixinRowDelete(row)">
              <template #reference>
                <el-button type="danger" link :loading="row.loading">删除</el-button>
              </template>
            </el-popconfirm>
          </template>
        </template>
      </vxe-grid>
    </template>
    <template #footer>
      <Pagination v-bind:child-msg="queryParams" @callFather="MixinFilter"></Pagination>
    </template>
    <template #dialog>
      <z-edit ref="refEdit" @update="MixinFilter"/>
    </template>
  </z-container-table>
</template>
<script>
import ZContainerTable from '@/views/jgdoor/components/container-table.vue'
import BaseTable from '@/views/jgdoor/mixins/table'
import Pagination from "@/components/Pagination.vue";
import ZEdit from './edit.vue'
import ZDept from "@/views/jgdoor/components/dept.vue";

export default {
  name: 'mendianList',
  mixins: [BaseTable],
  components: {ZDept, Pagination, ZContainerTable, ZEdit},
  data() {
    return {
      urlPrefix: 'jgdoor/base/mendianList/',

      queryParams: {
        dept_belong_id: '',
        search: '',
        show: undefined,

        page: 1,
        limit: 20,
        total: 0
      },
      zGridConfig: {
        data: [],
        height: 'auto',
        align: 'left',
        columns: [
          {field: 'rank', width: 80, title: '排序',},
          // {field: 'img', width: 110, align: 'center', headerAlign: 'left', title: '缩略图', slots: {default: 'img'}},
          {field: 'name', width: 200, title: '标题'},
          // {field: 'dept_belong_id', title: '地区', width: 140, slots: {default: 'dept'}},
          {field: 'addr', title: '地址', minWidth: 110},
          {field: 'show', title: '状态', width: 110, slots: {default: 'show'}},
          // {field: 'qrcode', title: '名片', width: 80, slots: {default: 'qrcode'}},
          {title: '操作', width: 110, align: 'center', slots: {default: 'operate'}}
        ]
      }
    }
  },
  mounted() {
    this.MixinFilter()
  }
}
</script>