<template>
  <div class="z-container" :class="{'ly-is-full':isFull}">
    <div class="tableSelect z-header" ref="tableSelect">
      <slot name="header"></slot>
    </div>

    <div class="table z-container-body">
      <div>
        <slot name="body"></slot>
        <slot name="table"></slot>
      </div>
    </div>

    <slot name="footer">
      <div class="z-container-footer">
        <slot name="button"></slot>
      </div>
    </slot>
    <slot name="dialog"></slot>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isFull: false,
    }
  },
  methods: {
    setFull() {
      this.isFull = !this.isFull
      window.dispatchEvent(new Event('resize'))
    },
  }
}
</script>