import {ajaxGet, ajaxDelete, ajaxPut, ajaxPost} from "@/api/request";

export default {
    data() {
        return {
            _mixinDefaultRowValue: {}, // 编辑的时候，先保存一遍默认值
            defaultTableConfigName: 'zGridConfig',
            defaultTableRefName: 'zGrid',
            urlPrefix: undefined,
            loading: false,
            queryParams: {
                page: 1,
                limit: 10,
                total: 0
            },
            zGridConfig: {
                loading: false,
                data: [],
            }
        }
    },
    methods: {
        MixinRowEdit(row) {
            // 进入编辑状态
            const zGrid = this.$refs[this.defaultTableRefName] // Vxe-Table对象

            if (zGrid.getEditRecord() === null) {
                this._mixinDefaultRowValue = JSON.parse(JSON.stringify(row))
                zGrid.setActiveRow(row) // 激活编辑
            } else {
                this.$message.warning('请先保存或取消上一个行编辑')
            }
        },
        MixinRowSave(row, {success, error} = {}) {
            if (!this.urlPrefix) {
                this.$message.error('请设置urlPrefix')
            } else {
                this[this.defaultTableConfigName].loading = this.loading = true
                return ajaxPut({
                    url: this.urlPrefix,
                    params: row
                }).then((result) => {
                    const {msg, data, code} = result
                    if (code === 2000) {
                        if ((success && success(result, row) !== false) || !success) {
                            const zGrid = this.$refs[this.defaultTableRefName]
                            this.$message.success(msg)
                            zGrid.reloadRow(row, data) // 局部更新行内容
                            zGrid.clearActived() // 取消编辑状态
                        }
                    } else if ((error && error(row) !== false) || !error) {
                        this.$message.error(msg)
                    }
                }).finally(() => {
                    this[this.defaultTableConfigName].loading = this.loading = false
                })
            }
        },
        MixinRowCreate(data, {success, error} = {}) {
            // 保存
            if (!this.urlPrefix) {
                this.$message.error('请设置urlPrefix')
            } else {
                return ajaxPost({
                    url: this.urlPrefix,
                    params: data,
                }).then(results => {
                    const {msg, code} = results
                    if (code === 2000) {
                        this.MixinFilter()

                        if ((success && success(results) !== false) || !success) {
                            this.$message.success(msg)
                            const zGrid = this.$refs[this.defaultTableRefName]
                            zGrid.clearActived() // 取消编辑状态
                        }
                    } else if ((error && error(results) !== false) || !error) {
                        this.$message.error(msg)
                    }
                })
            }
        },
        MixinRowReload(row) {
            // 取消编辑
            const zGrid = this.$refs[this.defaultTableRefName]
            zGrid.reloadRow(row, this._mixinDefaultRowValue) // 恢复默认值
            zGrid.clearActived() // 取消激活
            this.loading = false
        },
        MixinRowDelete(row, {success, error} = {}) {
            // 删除
            if (!this.urlPrefix) {
                this.$message.error('请设置urlPrefix')
            } else {
                row.loading = true
                return ajaxDelete({
                    url: `${this.urlPrefix}${row.id}/`
                }).then((result) => {
                    const {msg, code} = result
                    if (code === 2000) {
                        this.MixinFilter()
                        // 判断是否有回调函数
                        if ((success && success(result, row) !== false) || !success) {
                            const zGrid = this.$refs[this.defaultTableRefName]
                            this.$message.success(msg)
                            zGrid.clearActived()
                        }
                    } else if ((error && error(row) !== false) || !error) {
                        this.$message.error(msg)
                    }
                }).finally(() => {
                    row.loading = false
                })
            }
        },
        MixinFilter(queryParams = {}, {success, error} = {}) {
            if (!this.urlPrefix) {
                this.$message.error('请设置urlPrefix')
            } else {
                this[this.defaultTableConfigName].loading = this.loading = true
                return ajaxGet({
                    url: this.urlPrefix,
                    params: Object.assign({}, this.queryParams, queryParams)
                }).then(result => {
                    if (result.code === 2000) {
                        const {total, page, limit, data} = result.data

                        // 判断是否有回调函数
                        if (success && success(result) === false) {
                            //
                        } else {
                            this[this.defaultTableConfigName].data = data
                        }
                        this.queryParams.total = total
                        this.queryParams.page = page
                        this.queryParams.limit = limit
                    } else if ((error && error(result) !== false) || !error) {
                        this.$message.error(result.msg)
                    }
                }).finally(() => {
                    this[this.defaultTableConfigName].loading = this.loading = false
                })
            }
        }
    }

}
