<template>
  <z-container-table>
    <template #header>
      <el-form inline :model="queryParams" label-position="left">
        <el-form-item>
          <el-input v-model="queryParams.search" clearable></el-input>
        </el-form-item>
<!--        <el-form-item>-->
<!--          <z-chanpin-classify-select v-model="queryParams.classify"></z-chanpin-classify-select>-->
<!--        </el-form-item>-->
        <el-form-item>
          <el-button icon="Search" type="primary" @click="MixinFilter"></el-button>
        </el-form-item>

        <el-form-item>
          <el-button @click="$refs.refEdit.show({},{onSubmit:MixinRowCreate})">添加</el-button>
        </el-form-item>
<!--        <el-form-item>-->
<!--          <el-button @click="$refs.refChanPinClassify.show()">分类管理</el-button>-->
<!--        </el-form-item>-->
      </el-form>
    </template>
    <template #table>
      <vxe-grid v-bind="zGridConfig" ref="zGrid">
        <template #img="{ row }">
          <el-popover :hide-after="100" placement="left" :width="100" trigger="hover">
            <template #reference>
              <img :src="row.img" style="height: 30px;width:auto;">
            </template>
            <template #default>
              <el-image :src="row.img"></el-image>
            </template>
          </el-popover>
        </template>
        <template #operate="{ row }">
          <el-button type="primary" link :loading="row.loading" @click="$refs.refEdit.show(row,{onChange:MixinRowSave})">编辑</el-button>
          <el-popconfirm confirm-button-text="是" cancel-button-text="否" title="是否删除?" :hide-after="0" @confirm="MixinRowDelete(row)">
            <template #reference>
              <el-button type="danger" link :loading="row.loading">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </vxe-grid>
    </template>
    <template #footer>
      <Pagination v-bind:child-msg="queryParams" @callFather="MixinFilter"></Pagination>
    </template>
    <template #dialog>
      <z-edit ref="refEdit" @update="MixinFilter"/>
      <z-chan-pin-classify ref="refChanPinClassify" />
    </template>
  </z-container-table>
</template>
<script>
import ZContainerTable from '@/views/jgdoor/components/container-table.vue'
import BaseTable from '@/views/jgdoor/mixins/table'
import Pagination from "@/components/Pagination.vue";
import ZEdit from './edit.vue'
import ZChanPinClassify from '@/views/jgdoor/chanpin/chanpinList/chanpinClassify.vue'
import ZChanpinClassifySelect from '@/views/jgdoor/components/chanpin-classify.vue'

export default {
  name: 'chanpinVideos',
  mixins: [BaseTable],
  components: {Pagination, ZContainerTable, ZEdit, ZChanPinClassify, ZChanpinClassifySelect},
  data() {
    return {
      urlPrefix: 'jgdoor/base/chanpinVideos/',

      queryParams: {
        // classify: '',
        search: '',

        page: 1,
        limit: 20,
        total: 0
      },
      zGridConfig: {
        data: [],
        height: 'auto',
        align: 'left',
        columns: [
          {field: 'rank', width: 80, title: '排序',},
          // {field: 'img', align: "center", headerAlign: "left", width: 110, title: '缩略图', slots: {default: 'img'}},
          {field: 'title', minWidth: 220, title: '标题'},
          {title: '操作', width: 110, align: 'center', slots: {default: 'operate'}}
        ]
      }
    }
  },
  mounted() {
    this.MixinFilter()
  }
}
</script>