<template>
  <div class="z-upload-video-list">
    <div class="from">
      <slot name="before"></slot>
      <el-upload :accept="accept" list-type="picture" multiple action="" :disabled="disabled" :show-file-list="false" ref="refUpload" :http-request="uploadRequest" :on-success="uploadSuccess" :before-upload="beforeUpload">
        <el-button-group style="display: inline-flex">
          <el-button :loading="loading" icon="Film" type="primary">选择视频</el-button>
          <el-button icon="Delete" @click.stop="onClear"></el-button>
        </el-button-group>
      </el-upload>
      <span v-if="valueList.length > 0" class="from-small">已选择 {{ valueList.length }} 个视频（视频可拖动排序）</span>
      <el-image-viewer v-if="viewer.show" :initial-index="viewer.index" @close="viewer.show=false" :url-list="valueList"></el-image-viewer>
    </div>
    <div class="view" v-if="valueList.length > 0">
      <vue-draggable :list="valueList" item-key="id">
        <template #item="{ element, index }">
          <div class="view-item">
            <video disablePictureInPicture controlslist="nodownload noplaybackrate" class="view-img" :src="element" controls></video>
            <div class="view-hover">
              <Delete class="view-icon" @click="removeVideo(index)"/>
            </div>
          </div>
        </template>
      </vue-draggable>
    </div>
  </div>
</template>

<script>
import {platformsettingsUploadPlatformImg} from "@/api/api";
import {Delete, Pointer, View} from "@element-plus/icons-vue";
import VueDraggable from "vuedraggable"

export default {
  name: "baseUploadVideoList",
  components: {Pointer, Delete, View, VueDraggable},
  data() {
    return {
      loading: false,
      viewer: {
        show: false,
        src: ''
      }
    }
  },
  props: {
    accept: {
      // 类型说明
      // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#unique_file_type_specifiers
      type: String,
      default: ".mp4"
    },
    modelValue: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    valueList: {
      set(v) {
        this.$emit('update:modelValue', v); // visible改变时同步父组件modelValue的值
      },
      get() {
        return this.modelValue
      }
    }
  },
  methods: {
    onClear() {
      this.valueList = []
      this.$emit('clear')
    },
    removeVideo(index) {
      this.valueList.splice(index, 1);
    },
    beforeUpload(file) {
      const isJPG = file.type === 'video/mp4';
      if (!isJPG) {
        this.$message.error('仅支持MP4格式的视频!');
        return false
      }
      return isJPG;
    },
    async uploadRequest(param) {
      this.loading = true
      let obj = await platformsettingsUploadPlatformImg(param)
      if (obj.code === 2000) {
        let res = ''
        if (obj.data.data[0].indexOf("://") >= 0) {
          res = obj.data.data[0]

        } else {
          res = url.split('/api')[0] + obj.data.data[0]
        }
        this.$emit('upload', res)
        this.valueList.push(res)
      } else {
        this.$message.warning(res.msg)
      }
      this.loading = false
    },
    uploadSuccess() {
      this.$refs.refUpload.clearFiles()
    }
  },
}
</script>

<style lang="scss" scoped>
.z-upload-video-list {
  width: 100%;

  .from {
    display: flex;
    //justify-content: center;
    align-items: center;

    .from-small, .el-upload {
      flex: 0 0 auto;
    }
  }

  .from-small {
    margin-left: 15px;
    color: #888;
  }

  .view {
    .view-item {
      user-select: none;
      margin-top: 5px;
      position: relative;
      display: inline-block;

      &:hover {
        .view-hover {
          display: flex;
        }
      }

      & + .view-item {
        margin-left: 15px;
      }
    }

    .view-img {
      border: 1px solid #ddd;
      padding: 5px;
      height: 150px;
      width: auto;
      object-fit: cover;
    }

    .view-hover {
      display: none;
      position: absolute;
      top: 5px;
      right: 5px;
      justify-content: center;
      align-items: center;
      background: rgba(3, 3, 3, 0.6);
      border-radius: 5px 5px;
    }

    .view-icon {
      color: #fff;
      font-weight: bold;
      width: 20px;
      height: 20px;
      margin: 10px;
      cursor: pointer;

      &:hover {
        color: #ddd;
      }
    }
  }
}

</style>