<template>
  <z-container-table>
    <template #header>
      <el-form inline :model="queryParams" label-position="left">
        <el-form-item>
          <el-input v-model="queryParams.search" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <z-dept v-model="queryParams.dept_belong_id"></z-dept>
        </el-form-item>
        <el-form-item>
          <el-select v-model="queryParams.audit" clearable placeholder="状态" style="width: 110px;">
            <el-option :value="true" label="已审核"></el-option>
            <el-option :value="false" label="未审核"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button icon="Search" type="primary" @click="MixinFilter"></el-button>
        </el-form-item>

        <el-form-item>
          <el-button @click="$refs.refEdit.show({},{onSubmit:MixinRowCreate})">添加</el-button>
        </el-form-item>
      </el-form>
    </template>
    <template #table>
      <vxe-grid v-bind="zGridConfig" ref="zGrid">
        <template #editAudit="{ row }">
          <el-switch v-model="row.audit" @change="MixinRowSave(row)"/>
        </template>
        <template #audit="{ row }">
          <el-tag type="success" :disable-transitions="true" v-if="row.audit">审核通过</el-tag>
          <el-tag type="warning" :disable-transitions="true" v-else>未审核</el-tag>
        </template>
        <template #dept="{ row }">
          <z-dept :text="true" v-model="row.dept_belong_id"></z-dept>
        </template>
        <template #settop="{row}">
          <el-tag type="success" v-if="row.top">精</el-tag>
        </template>
        <template #img="{ row }">
          <el-popover :hide-after="100" placement="left" :width="100" trigger="hover">
            <template #reference>
              <img :src="row.img" style="height: 30px;width:auto;">
            </template>
            <template #default>
              <el-image :src="row.img"></el-image>
            </template>
          </el-popover>
        </template>
        <template #operate="{ row }">
          <el-button type="primary" link :loading="row.loading" @click="$refs.refEdit.show(row,{onChange:MixinRowSave})">编辑</el-button>
          <el-popconfirm confirm-button-text="是" cancel-button-text="否" title="是否删除?" :hide-after="0" @confirm="MixinRowDelete(row)">
            <template #reference>
              <el-button type="danger" link :loading="row.loading">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </vxe-grid>
    </template>
    <template #footer>
      <Pagination v-bind:child-msg="queryParams" @callFather="MixinFilter"></Pagination>
    </template>
    <template #dialog>
      <z-edit ref="refEdit" @update="MixinFilter"/>
    </template>
  </z-container-table>
</template>
<script>
import ZContainerTable from '@/views/jgdoor/components/container-table.vue'
import BaseTable from '@/views/jgdoor/mixins/table'
import Pagination from "@/components/Pagination.vue";
import ZEdit from './edit.vue'
import ZDept from "@/views/jgdoor/components/dept.vue";
import {getIsSuper} from "@/views/jgdoor/api";

export default {
  name: 'huodongList',
  mixins: [BaseTable],
  components: {ZDept, Pagination, ZContainerTable, ZEdit},
  data() {
    return {
      urlPrefix: 'jgdoor/base/huodongList/',

      queryParams: {
        dept_belong_id: '',
        search: '',
        audit: undefined,

        page: 1,
        limit: 20,
        total: 0
      },
      zGridConfig: {
        data: [],
        height: 'auto',
        align: 'left',
        columns: [
          {field: 'rank', width: 80, title: '排序',},
          // {field: 'img', width: 110, align: 'center', headerAlign: 'left', title: '缩略图', slots: {default: 'img'}},
          {field: 'dept_belong_id', title: '城市', width: 110, slots: {default: 'dept'}},
          {field: 'title', minWidth: 200, title: '标题'},
          {field: 'audit', width: 200, title: '审核', visible: false, slots: {default: 'editAudit'}},
          {field: 'audit-default', width: 200, title: '审核', visible: false, slots: {default: 'audit'}},
          {title: '操作', width: 110, align: 'center', slots: {default: 'operate'}}
        ]
      }
    }
  },
  created() {
    getIsSuper().then(({data}) => {
      if (data) {
        this.$refs.zGrid.showColumn('audit')
      } else {
        this.$refs.zGrid.showColumn('audit-default')
      }
    })
    this.MixinFilter()
  }
}
</script>