<template>
  <vxe-modal ref="dialog" title="添加" :show-footer="true" width="90%" height="95vh" min-width="800px" v-model="visible">
    <template #default>
      <el-form ref="zForm" :model="formData" label-width="120px" :rules="rules">
        <el-form-item label="类别" placeholder="请选择" prop="classify">
          <xueyuan-classify v-model="formData.classify" @change="onClassifyChange"></xueyuan-classify>
        </el-form-item>
        <el-form-item label="标题" placeholder="请输入" prop="title">
          <el-input v-model="formData.title"></el-input>
        </el-form-item>
        <template v-if="formData.classify === '学习视频'">
          <el-form-item label="微信视频ID" placeholder="请输入" prop="video_wechat_id">
            <el-input v-model="formData.video_wechat_id"></el-input>
          </el-form-item>
          <el-form-item label="图片/视频" placeholder="请选择" prop="img">
            <upload-video-ex thumbnail v-model:img="formData.img" v-model:video="formData.video"/>
          </el-form-item>
        </template>
        <template v-else>
          <el-form-item label="图片" placeholder="请选择" prop="img">
            <upload-image thumbnail v-model="formData.img"/>
          </el-form-item>
          <el-form-item label="描述" placeholder="请输入" prop="small">
            <el-input type="textarea" style="width: 600px" :rows="3" v-model="formData.small"></el-input>
          </el-form-item>
          <el-form-item label="详情" placeholder="请输入" prop="content">
            <TEditor v-model="formData.content" />
          </el-form-item>
        </template>
        <el-form-item label="排序">
          <el-input type="number" style="width: 120px" v-model="formData.rank"/>
        </el-form-item>
      </el-form>
    </template>

    <template #footer>
      <div class="el-dialog--center">
        <vxe-button status="primary" :loading="loading" @click="onSubmit">保存</vxe-button>
      </div>
    </template>
  </vxe-modal>
</template>
<script>
import UploadVideoEx from '@/views/jgdoor/components/upload-video-ex.vue'
import UploadImage from '@/views/jgdoor/components/upload-image.vue'
import TEditor from '@/components/TEditor'
import XueyuanClassify from '@/views/jgdoor/components/xueyuan-classify.vue'

export default {
  components: {UploadVideoEx, XueyuanClassify, TEditor, UploadImage},
  emits: ['update'],
  data() {
    return {
      loading: false,
      visible: false,
      formData: {
        classify: '学习视频',
        title: '',
        small: '',
        content: '',
        img: '',
        video: '',
        video_wechat_id: '',
        rank: 100,
      },
      rules: {
        classify: [{required: true, message: '此项不能为空', trigger: 'blur'},],
        title: [{required: true, message: '此项不能为空', trigger: 'blur'},],
        video_wechat_id: [{required: true, message: '此项不能为空', trigger: 'blur'},],
      },

      submitFunc: undefined,
      mode: undefined,
    }
  },
  computed: {},
  methods: {
    onClassifyChange(){
      if(this.formData.classify === '学习视频'){
        this.rules = {
          classify: [{required: true, message: '此项不能为空', trigger: 'blur'},],
          title: [{required: true, message: '此项不能为空', trigger: 'blur'},],
          video_wechat_id: [{required: true, message: '此项不能为空', trigger: 'blur'},],
        }
      }else{
        this.rules = {
          classify: [{required: true, message: '此项不能为空', trigger: 'blur'},],
          title: [{required: true, message: '此项不能为空', trigger: 'blur'},],
          small: [{required: true, message: '此项不能为空', trigger: 'blur'},],
          img: [{required: true, message: '此项不能为空', trigger: 'blur'},],
          content: [{required: true, message: '此项不能为空', trigger: 'blur'},],
        }
      }
      this.$refs.zForm?.clearValidate()
    },
    show(data, {onSubmit, onChange} = {}) {
      // 初始化数据
      Object.assign(this.$data, this.$options.data())
      Object.assign(this.formData, data)
      this.onClassifyChange()

      this.visible = true
      if (onSubmit) {
        this.mode = 'add'
        this.submitFunc = onSubmit
      } else {
        this.mode = 'create'
        this.submitFunc = onChange
      }
    },
    onSubmit() {
      this.$refs.zForm.validate((valid) => {
        if (valid) {
          this.loading = true
          this.submitFunc(this.formData, {
            success: () => {
              this.visible = false
              this.$emit('update')
              return true
            }
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>