export const getFileURL = function (file) {
    var url = null;
    if (window.createObjectURL != undefined) { // basic
        url = window.createObjectURL(file);
    } else if (window.URL != undefined) { // mozilla(firefox)
        url = window.URL.createObjectURL(file);
    } else if (window.webkitURL != undefined) { // webkit or chrome
        url = window.webkitURL.createObjectURL(file);
    }
    return url;
}


// 获取视频封帧数封面base64
/**
 因为视频中有手机中横屏视频也有竖屏视频  本方法内含视频中间区域截屏
 而非全部截屏 所以有些关于长宽的判断
 注注注：还有一点需要注明的 也是费了我不少时间才大约弄明白 浏览器好像对base64截图有大小的限制
 刚开始写的时候没有限制大小取全屏的截图 结果cavas绘制的图 总是上面一部分
 下面并未绘制  这时候有两个方案 一是降低清晰度 第二是修改浏览器对这个的默认大小
 具体方法百度 总之希望大家不要像我这样自查cavans方法 耽误太多时间 这不是我们的问题
 */
export const getVideoposter = async (url, width=512) => {
    return new Promise((resolve, reject) => {
        let imgbase64 = null;
        let video = document.createElement("video");
        video.setAttribute("crossOrigin", "Anonymous");
        video.setAttribute("src", url);
        video.setAttribute("preload", "auto"); // 不设置该项就不会开启预先加载视频，那么拿到的会是黑屏
        //如果不设置currentTime，画出来的图片是空的 当前为截取第0.001秒
        video.currentTime = 0.001;
        video.addEventListener("loadeddata", function () {
            let canvas = document.createElement("canvas");
            // let vWidth = video.videoWidth;
            // let vHeight = video.videoHeight;
            let VideoWidth, videoHeight
            if(video.videoWidth > width){
                VideoWidth = width
                videoHeight = video.videoHeight / (video.videoWidth / width)
            }else{
                VideoWidth = video.videoWidth
                videoHeight = video.videoHeight
            }
            canvas.width = VideoWidth;
            canvas.height = videoHeight;
            canvas
                .getContext("2d")
                .drawImage(video, 0, 0, VideoWidth, videoHeight);
            imgbase64 = canvas.toDataURL("image/jpeg");
            // 返回base64格式图片
            resolve(imgbase64);
        });
    });
};

// 将base64编码转换为file对象。
export const base64ToFile = (dataurl, filename = '') => {
    let arr = dataurl.split(',')
    let mime = arr[0].match(/:(.*?);/)[1]
    let suffix = mime.split('/')[1]
    let bstr = atob(arr[1])
    let n = bstr.length
    let u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], `${filename}.${suffix}`, {
        type: mime
    })
}