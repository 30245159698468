<template>
  <div>
    <el-select v-model="bindValue" clearable>
      <el-option v-for="item in jgdoorStore.slideshowClassifyOptions" :key="item.value" :value="item.value" :label="item.label"></el-option>
    </el-select>
  </div>
</template>
<script>
import {jgdoorStore} from "@/store/jgdoor";

export default {
  setup() {
    return {
      jgdoorStore: jgdoorStore()
    }
  },
  props: {
    modelValue: String
  },
  computed: {
    bindValue: {
      get() {
        return this.modelValue
      },
      set(v) {
        this.$emit('update:modelValue', v)
      }
    }
  },
  data() {
    return {}
  },
  created() {
    this.jgdoorStore.getSlideshowClassifyOptions()
  }
}
</script>