<template>
  <z-container-table>
    <template #header>
      <el-form class="form" inline :model="queryParams" label-position="left">
        <el-form-item label="城市">
          <z-dept v-model="queryParams.dept_belong_id"></z-dept>
        </el-form-item>
        <el-form-item label="日期">
          <el-date-picker style="width: 140px;" value-format="YYYY-MM-DD" date-format="YYYY-MM-DD" v-model="queryParams.date"/>
        </el-form-item>
        <el-form-item label="">
          <el-button :loading="loading" icon="Search" type="primary" @click="onFilter"></el-button>
        </el-form-item>

        <el-form-item style="float:right;">
          <el-tag :disable-transitions="true" v-for="item in countInfo">{{ item.platform }}: {{ item.count }}</el-tag>
        </el-form-item>
      </el-form>
    </template>
    <template #table>
      <vxe-grid v-bind="zGridConfig" ref="zGrid">
        <template #dept="{ row }">
          <z-dept :text="true" v-model="row.dept_belong_id"></z-dept>
        </template>
        <template #img="{ row }">
          <el-popover :hide-after="100" placement="left" :width="100" trigger="hover">
            <template #reference>
              <img :src="row.img" style="height: 30px;width:auto;">
            </template>
            <template #default>
              <el-image :src="row.img"></el-image>
            </template>
          </el-popover>
        </template>
        <template #operate="{ row }">
          <el-button type="primary" link :loading="row.loading" @click="$refs.refEdit.show(row,{onChange:MixinRowSave})">编辑</el-button>
          <el-popconfirm confirm-button-text="是" cancel-button-text="否" title="是否删除?" :hide-after="0" @confirm="MixinRowDelete(row)">
            <template #reference>
              <el-button type="danger" link :loading="row.loading">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </vxe-grid>
    </template>
    <template #footer>
      <Pagination v-bind:child-msg="queryParams" @callFather="MixinFilter"></Pagination>
    </template>
  </z-container-table>
</template>
<script>
import ZContainerTable from '@/views/jgdoor/components/container-table.vue'
import BaseTable from '@/views/jgdoor/mixins/table'
import Pagination from "@/components/Pagination.vue";
import SlideshowClassify from '@/views/jgdoor/components/slideshow-classify.vue'
import ZDept from "@/views/jgdoor/components/dept.vue";
import dayjs from "dayjs";
import {ajaxGet} from "@/api/request";

export default {
  name: 'statistics',
  mixins: [BaseTable],
  components: {ZDept, Pagination, ZContainerTable, SlideshowClassify},
  data() {
    return {
      urlPrefix: 'jgdoor/base/statistics/',
      loading: false,
      countInfo: [],
      queryParams: {
        dept_belong_id: undefined,
        date: dayjs().format('YYYY-MM-DD'),

        page: 1,
        limit: 20,
        total: 0
      },
      zGridConfig: {
        data: [],
        height: 'auto',
        align: 'center',
        headerAlign: 'left',
        columns: [
          {field: 'date', width: 120, title: '日期'},
          {field: 'platform', width: 120, title: '来源'},
          {field: 'sharer', width: 120, title: '分享人'},
          {field: 'dept_belong_id', title: '城市', width: 110, slots: {default: 'dept'}},
          {field: 'ip', minWidth: 220, title: 'IP'},
          {field: 'count', width: 220, title: '浏览量'},
        ]
      }
    }
  },
  methods: {
    onFilter() {
      this.MixinFilter()
      if (this.queryParams.date) {
        ajaxGet({
          url: 'jgdoor/base/statistics/info/',
          params: {date: this.queryParams.date}
        }).then(({data}) => {
          this.countInfo = data
        })
      }else{
        this.countInfo = []
      }
    }
  },
  mounted() {
    this.onFilter()
  }
}
</script>

<style lang="scss" scoped>
.form {
  display: block;
}
</style>