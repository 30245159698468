<template>
  <vxe-modal ref="dialog" title="添加" v-if="visible" :show-footer="true" width="60%" min-width="800px" v-model="visible">
    <template #default>
      <el-form ref="zForm" :model="formData" label-width="120px" :rules="rules">
        <el-form-item label="类别" placeholder="请选择" prop="classify">
          <z-chanpin-classify :check-strictly="false" v-model="formData.classify"></z-chanpin-classify>
        </el-form-item>
        <el-form-item label="产品名称" placeholder="请输入" prop="name">
          <el-input v-model="formData.name"></el-input>
        </el-form-item>
        <el-form-item label="打开方式" placeholder="请选择" prop="open_mode">
          <el-input v-model="formData.open_mode"></el-input>
        </el-form-item>
        <el-form-item label="适用范围" placeholder="请输入" prop="scope">
          <el-input v-model="formData.scope"></el-input>
        </el-form-item>
        <el-form-item label="其他" placeholder="请输入" prop="more">
          <el-input v-model="formData.more"></el-input>
        </el-form-item>
        <el-form-item label="图片" placeholder="请选择" prop="img_list">
          <upload-image-list thumbnail v-model="formData.img_list"/>
        </el-form-item>
        <el-form-item label="排序">
          <el-input type="number" style="width: 120px" v-model="formData.rank"/>
        </el-form-item>
      </el-form>
    </template>

    <template #footer>
      <div class="el-dialog--center">
        <vxe-button status="primary" :loading="loading" @click="onSubmit">保存</vxe-button>
      </div>
    </template>
  </vxe-modal>
</template>
<script>
import UploadImageList from '@/views/jgdoor/components/upload-image-list.vue'
import UploadVideoEx from '@/views/jgdoor/components/upload-video-ex.vue'
import ZChanpinClassify from '@/views/jgdoor/components/chanpin-classify.vue'

export default {
  components: {UploadImageList, UploadVideoEx, ZChanpinClassify},
  emits: ['update'],
  data() {
    return {
      loading: false,
      visible: false,
      formData: {
        classify: undefined,
        img: '',
        img_list: [],
        name: '',
        open_mode: '',
        scope: '',
        more: '',
        rank: 100,
      },
      rules: {
        classify: [{required: true, message: '此项不能为空', trigger: 'blur'},],
        name: [{required: true, message: '此项不能为空', trigger: 'blur'},],
        img_list: [{required: true, message: '此项不能为空', trigger: 'blur'},],
      },

      submitFunc: undefined,
      mode: undefined,
    }
  },
  computed: {},
  methods: {
    show(data, {onSubmit, onChange} = {}) {
      // 初始化数据
      Object.assign(this.$data, this.$options.data())
      Object.assign(this.formData, data)

      this.visible = true
      if (onSubmit) {
        this.mode = 'add'
        this.submitFunc = onSubmit
      } else {
        this.mode = 'create'
        this.submitFunc = onChange
      }
    },
    onSubmit() {
      this.$refs.zForm.validate((valid) => {
        if (valid) {
          if (this.formData.img_list.length == 0) {
            this.$message.error("请选择图片")
            return
          } else {
            this.formData.img = this.formData.img_list[0]
          }

          this.loading = true
          this.submitFunc(this.formData, {
            success: () => {
              this.visible = false
              this.$emit('update')
              return true
            }
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>