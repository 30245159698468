<template>
  <div class="z-coord">
    <el-input class="item" v-model="bindLatitude"></el-input>
    <el-input class="item" v-model="bindLongitude"></el-input>
    <el-input class="item item-temp" v-model="temp" placeholder="快捷填充（xx.xxx, xx.xxxx）" @change="onTemp"></el-input>
  </div>
</template>

<script>
export default {
  props: {
    longitude: Number,
    latitude: Number,
  },
  computed: {
    bindLongitude: {
      get() {
        return this.longitude
      },
      set(v) {
        this.$emit('update:longitude', v)
      }
    },
    bindLatitude: {
      get() {
        return this.latitude
      },
      set(v) {
        this.$emit('update:latitude', v)
      }
    },
  },
  data() {
    return {
      temp: ""
    }
  },
  methods: {
    onTemp() {
      const data = this.temp.trim().split(/[\s,]+/)
      if (data.length === 2) {
        const lg = parseFloat(data[0])
        const la = parseFloat(data[1])
        if (lg && la) {
          this.bindLatitude = lg
          this.bindLongitude = la
        }
      }
      this.temp = ""
    }
  }
}
</script>

<style lang="scss">
.z-coord{
  .item{
    display: inline-block;
    width: 120px;
    & + .item{
      margin-left: 15px;
    }
  }
  .item-temp{
    width: 220px;
  }
}
</style>