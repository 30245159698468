<template>
  <z-container-table>
    <template #header>
      <el-form inline :model="queryParams" label-position="left">
        <el-form-item>
          <el-input v-model="queryParams.search" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <z-dept v-model="queryParams.dept_belong_id"></z-dept>
        </el-form-item>
        <el-form-item>
          <el-button icon="Search" type="primary" @click="MixinFilter"></el-button>
        </el-form-item>
      </el-form>
    </template>
    <template #table>
      <vxe-grid v-bind="zGridConfig" ref="zGrid">
        <template #dept="{ row }">
          <z-dept :text="true" v-model="row.dept_belong_id"></z-dept>
        </template>
        <template #img="{ row }">
          <el-popover :hide-after="100" placement="left" :width="100" trigger="hover">
            <template #reference>
              <img :src="row.img" style="height: 30px;width:auto;">
            </template>
            <template #default>
              <el-image :src="row.img"></el-image>
            </template>
          </el-popover>
        </template>
<!--        <template #operate="{ row }">-->
<!--          <el-popconfirm confirm-button-text="是" cancel-button-text="否" title="是否删除?" :hide-after="0" @confirm="MixinRowDelete(row)">-->
<!--            <template #reference>-->
<!--              <el-button type="danger" link :loading="row.loading">删除</el-button>-->
<!--            </template>-->
<!--          </el-popconfirm>-->
<!--        </template>-->
      </vxe-grid>
    </template>
    <template #footer>
      <Pagination v-bind:child-msg="queryParams" @callFather="MixinFilter"></Pagination>
    </template>
    <template #dialog>
    </template>
  </z-container-table>
</template>
<script>
import ZContainerTable from '@/views/jgdoor/components/container-table.vue'
import BaseTable from '@/views/jgdoor/mixins/table'
import Pagination from "@/components/Pagination.vue";
import ZDept from "@/views/jgdoor/components/dept.vue";

export default {
  name: 'liuyan',
  mixins: [BaseTable],
  components: {ZDept, Pagination, ZContainerTable},
  data() {
    return {
      urlPrefix: 'jgdoor/base/liuyan/',
      queryParams: {
        dept_belong_id: '',
        search: '',

        page: 1,
        limit: 20,
        total: 0
      },
      zGridConfig: {
        data: [],
        height: 'auto',
        align: 'left',
        columns: [
          {field: 'classify', width: 120, title: '来源'},
          {field: 'name', width: 120, title: '姓名'},
          {field: 'phone', width: 180, title: '电话'},
          // {field: 'dept_belong_id', title: '定位城市', width: 110, slots: {default: 'dept'}},
          {field: 'area', width: 280, title: '填写地区'},
          {field: 'need', width: 220, title: '需求'},
          {field: 'content', minWidth: 220, title: '留言内容'},
          {field: 'create_datetime', width: 150, title: '留言时间'},
          {title: '操作', width: 110, align: 'center', slots: {default: 'operate'}}
        ]
      }
    }
  },
  mounted() {
    this.MixinFilter()
  }
}
</script>