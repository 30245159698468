<template>
  <div class="z-upload-video">
    <div class="from">
      <slot name="before"></slot>
      <el-upload :accept="accept" action="" :disabled="disabled" :on-error="onError" :show-file-list="false" ref="refUpload" :http-request="uploadRequest" :on-success="uploadSuccess" :before-upload="beforeUpload">
        <el-button-group style="display: inline-flex">
          <el-button :loading="loading" icon="Film" type="primary">选择视频</el-button>
          <el-button icon="Delete" @click.stop="onClear"></el-button>
        </el-button-group>
      </el-upload>
      <el-image-viewer v-if="showImage" @close="showImage=false" :url-list="[value]"></el-image-viewer>
    </div>
    <div class="view" v-if="value">
      <video disablePictureInPicture controlslist="nodownload noplaybackrate" class="view-img" :src="value" controls></video>
    </div>
  </div>
</template>

<script>
import {platformsettingsUploadPlatformImg} from "@/api/api";

export default {
  name: "baseUploadVideo",
  emits: ['clear', 'upload', 'update:modelValue'],
  data() {
    return {
      loading: false,
      showImage: false,
    }
  },
  props: {
    accept: {
      // 类型说明
      // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#unique_file_type_specifiers
      type: String,
      default: ".mp4"
    },
    modelValue: {
      type: String,
      default: ""
    },
    disabledInput: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    value: {
      set(v) {
        this.$emit('update:modelValue', v); // visible改变时同步父组件modelValue的值
      },
      get() {
        return this.modelValue
      }
    }
  },
  methods: {
    onError(e){
      this.loading = false
    },
    onClear() {
      this.value = ''
      this.$emit('clear')
    },
    beforeUpload(file) {
      const isJPG = file.type === 'video/mp4';
      if (!isJPG) {
        this.$message.error('仅支持MP4格式的视频!');
        return false
      }
      return isJPG;
    },
    async uploadRequest(param) {
      this.loading = true
      let obj = await platformsettingsUploadPlatformImg(param)
      if (obj.code === 2000) {
        let res = ''
        if (obj.data.data[0].indexOf("://") >= 0) {
          res = obj.data.data[0]

        } else {
          res = url.split('/api')[0] + obj.data.data[0]
        }
        this.$emit('upload', res)
        this.value = res
      } else {
        this.$message.warning(res.msg)
      }
      this.loading = false
    },
    uploadSuccess() {
      this.$refs.refUpload.clearFiles()
    }
  },
}
</script>

<style lang="scss" scoped>
.z-upload-video {
  width: 100%;

  .from {
    display: flex;
  }

  .view {
    margin-top: 5px;

    .view-img {
      border: 1px solid #ddd;
      padding: 5px;
      height: 150px;
      width: auto;
      object-fit: cover;
    }
  }
}

</style>