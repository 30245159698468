<template>
  <vxe-modal ref="dialog" title="添加" v-if="visible" :show-footer="true" height="80vh" width="1200px" v-model="visible">
    <template #default>
      <el-form ref="zForm" :model="formData" label-width="120px" :rules="rules">
        <el-form-item label="上级" placeholder="请选择" prop="parent_id">
          <z-chanpin-classify v-model="formData.parent_id" @change="clearValidate"/>
        </el-form-item>
        <el-form-item label="名称" placeholder="请输入" prop="title">
          <el-input v-model="formData.title"></el-input>
        </el-form-item>
        <template v-if="!formData.parent_id">
          <el-form-item label="英文" placeholder="请输入" prop="en">
            <el-input v-model="formData.en"></el-input>
          </el-form-item>
          <el-form-item label="小图标" placeholder="请选择" prop="ico">
            <upload-image v-model="formData.ico"/>
          </el-form-item>
          <el-form-item label="首页导航图" placeholder="请选择" prop="img">
            <upload-image v-model="formData.img"/>
          </el-form-item>
        </template>
        <template v-else>
          <el-form-item label="优势" placeholder="请输入" prop="advantage_list">
            <div class="advantage_list">
              <div v-if="formData.advantage_list.length === 0">
                <el-button icon="Plus" type="primary" text @click="formData.advantage_list.push({title:'',img: ''})"></el-button>
              </div>
              <div v-else class="group" v-for="(item, index) in formData.advantage_list" :key="index">
                <el-input placeholder="标题" v-model="item.title"></el-input>
                <el-input placeholder="描述" v-model="item.small"></el-input>
                <upload-image v-model="item.img" />
                <el-button v-if="index === (formData.advantage_list.length - 1)" type="primary" icon="Plus" text @click="formData.advantage_list.push({title:'',img: ''})"></el-button>
                <el-button type="danger" text icon="Delete" @click="formData.advantage_list.splice(index, 1)"></el-button>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="性能" placeholder="请输入" prop="performance_list">
            <div class="performance_list">
              <div v-if="formData.performance_list.length === 0">
                <el-button icon="Plus" type="primary" text @click="formData.performance_list.push({title:'',img: ''})"></el-button>
              </div>
              <div v-else class="group" v-for="(item, index) in formData.performance_list" :key="index">
                <el-input placeholder="标题" v-model="item.title"></el-input>
                <el-input placeholder="描述" v-model="item.small"></el-input>
                <el-button v-if="index === (formData.performance_list.length - 1)" icon="Plus" text @click="formData.performance_list.push({title:'',img: ''})"></el-button>
                <el-button text icon="Delete" @click="formData.performance_list.splice(index, 1)"></el-button>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="详情" placeholder="请输入" prop="details_list">
            <upload-image-list thumbnail v-model="formData.details_list" />
          </el-form-item>
        </template>
        <el-form-item label="排序">
          <el-input type="number" style="width: 120px" v-model="formData.rank"/>
        </el-form-item>
      </el-form>
    </template>

    <template #footer>
      <div class="el-dialog--center">
        <vxe-button status="primary" :loading="loading" @click="onSubmit">保存</vxe-button>
      </div>
    </template>
  </vxe-modal>
</template>
<script>
import ZChanpinClassify from '@/views/jgdoor/components/chanpin-classify.vue'
import UploadImage from '@/views/jgdoor/components/upload-image.vue'
import {jgdoorStore} from "@/store/jgdoor";
import UploadVideoListEx from "@/views/jgdoor/components/upload-video-list-ex.vue";
import UploadImageList from "@/views/jgdoor/components/upload-image-list.vue";

export default {
  components: {UploadVideoListEx, ZChanpinClassify, UploadImage, UploadImageList},
  emits: ['update'],
  setup() {
    return {
      jgdoorStore: jgdoorStore()
    }
  },
  data() {
    return {
      loading: false,
      visible: false,
      formData: {
        parent_id: undefined,
        title: '',
        en: '',
        ico: '',
        advantage_list: [],
        performance_list: [],
        details_list: [],
        rank: 100,
      },
      rules: {
        title: [{required: true, message: '此项不能为空', trigger: 'blur'},],
        en: [{required: true, message: '此项不能为空', trigger: 'blur'},],
        ico: [{required: true, message: '此项不能为空', trigger: 'blur'},],
        img: [{required: true, message: '此项不能为空', trigger: 'blur'},],
        // advantage_list: [{required: true, message: '此项不能为空', trigger: 'blur'},],
        // performance_list: [{required: true, message: '此项不能为空', trigger: 'blur'},],
        details_list: [{required: true, message: '此项不能为空', trigger: 'blur'},],
        rank: [{required: true, message: '此项不能为空', trigger: 'blur'},],
      },

      submitFunc: undefined,
      mode: undefined,
    }
  },
  computed: {},
  methods: {
    clearValidate(){
      this.$refs.zForm.clearValidate()
    },
    show(data, {onSubmit, onChange} = {}) {
      // 初始化数据
      Object.assign(this.$data, this.$options.data())
      Object.assign(this.formData, data)

      this.visible = true
      if (onSubmit) {
        this.mode = 'add'
        this.submitFunc = onSubmit
      } else {
        this.mode = 'create'
        this.submitFunc = onChange
      }
    },
    onSubmit() {
      this.$refs.zForm.validate((valid) => {
        if (valid) {
          this.loading = true
          this.submitFunc(this.formData, {
            success: () => {
              this.visible = false
              this.jgdoorStore.getChanpinClassifyOptions()
              this.$emit('update')
              return true
            }
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.advantage_list{
  display: grid;
  grid-gap: 5px;
  .group{
    display: grid;
    grid-gap: 5px;
    grid-template-columns: 140px 1fr 1fr auto auto;
  }
}
.performance_list{
  display: grid;
  grid-gap: 5px;
  .group{
    display: grid;
    grid-gap: 5px;
    grid-template-columns: 140px 1fr auto auto;
  }
}
</style>