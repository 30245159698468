<template>
  <z-container-table style="height: 100%;">
    <template #header>
      <el-form class="form" inline :model="queryParams" label-position="left">
        <el-form-item>
          <el-date-picker style="width: 220px;" type="daterange" value-format="YYYY-MM-DD" date-format="YYYY-MM-DD" v-model="queryParams.date_range"/>
        </el-form-item>
        <el-form-item label="">
          <el-button :loading="loading" icon="Search" type="primary" @click="onFilter"></el-button>
        </el-form-item>
      </el-form>
    </template>
    <template #table>
      <el-scrollbar height="100%">
        <el-row>
          <el-col :span="12" style="display: flex;align-items: center;justify-content: center;padding: 30px;">
            <el-statistic title="IP数" :value="data.ipcount"/>
          </el-col>
          <el-col :span="12" style="display: flex;align-items: center;justify-content: center;padding: 30px;">
            <el-statistic title="浏览量" :value="data.count"/>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" style="padding: 10px;">
            <vxe-grid v-bind="zGridPlatform" ref="zGridPlatform"></vxe-grid>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" style="padding: 10px;">
            <vxe-grid v-bind="zGridSharer" ref="zGridSharer"></vxe-grid>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" style="padding: 10px;">
            <vxe-grid v-bind="zGridDept" ref="zGridDept">
              <template #dept="{ row }">
                <template v-if="row.dept_belong_id ==='无'">无</template>
                <z-dept v-else :text="true" v-model="row.dept_belong_id"></z-dept>
              </template>
            </vxe-grid>
          </el-col>
        </el-row>
      </el-scrollbar>
    </template>
  </z-container-table>
</template>
<script>
import ZContainerTable from '@/views/jgdoor/components/container-table.vue'
import BaseTable from '@/views/jgdoor/mixins/table'
import Pagination from "@/components/Pagination.vue";
import SlideshowClassify from '@/views/jgdoor/components/slideshow-classify.vue'
import ZDept from "@/views/jgdoor/components/dept.vue";
import dayjs from "dayjs";

export default {
  name: 'statistics',
  mixins: [BaseTable],
  components: {ZDept, Pagination, ZContainerTable, SlideshowClassify},
  data() {
    return {
      urlPrefix: 'jgdoor/base/statistics/month/',
      data: {
        ipcount: 0,
        count: 0,
      },
      queryParams: {
        date_range: [dayjs().subtract(7, 'days').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
        date__gte: '',
        date__lte: '',

        page: 1,
        limit: 20,
        total: 0
      },
      zGridPlatform: {
        data: [],
        size: 'mini',
        align: 'center',
        headerAlign: 'left',
        loading: false,
        columns: [
          {field: 'platform', title: '来源'},
          {field: 'ip', title: 'IP数'},
          {field: 'count', title: '浏览量'},
        ]
      },
      zGridSharer: {
        data: [],
        align: 'center',
        headerAlign: 'left',
        height: 400,
        size: 'mini',
        loading: false,
        columns: [
          {field: 'sharer', title: '分享人'},
          {field: 'ip', title: 'IP数'},
          {field: 'count', title: '浏览量'},
        ]
      },
      zGridDept: {
        data: [],
        align: 'center',
        headerAlign: 'left',
        size: 'mini',
        loading: false,
        height: 400,
        columns: [
          {field: 'dept', title: '地区', slots: {default: 'dept'}},
          {field: 'ip', title: 'IP数'},
          {field: 'count', title: '浏览量'},
        ]
      }
    }
  },
  methods: {
    onFilter() {
      this.queryParams.date__gte = this.queryParams.date_range[0]
      this.queryParams.date__lte = this.queryParams.date_range[1]
      this.zGridPlatform.loading = true
      this.zGridDept.loading = true
      this.zGridSharer.loading = true
      this.MixinFilter(this.queryParams, {
        success: ({data}) => {
          this.zGridPlatform.data = data.platform
          this.zGridDept.data = data.dept
          this.zGridSharer.data = data.sharer
          Object.assign(this.data, data)

          this.zGridPlatform.loading = false
          this.zGridDept.loading = false
          this.zGridSharer.loading = false
          return false
        },
        error: ()=>{
          this.zGridPlatform.loading = false
          this.zGridDept.loading = false
          this.zGridSharer.loading = false
          return false
        }
      })
    }
  },
  mounted() {
    this.onFilter()
  }
}
</script>

<style lang="scss" scoped>
.form {
  display: block;
}
</style>