<template>
  <el-cascader @change="onChange" v-model="bindValue" :options="jgdoorStore.xueyuanClassifyOptions" clearable :props="props"/>
</template>
<script>
import {ajaxGet} from "@/api/request";
import {jgdoorStore} from "@/store/jgdoor";

export default {
  setup() {
    return {
      jgdoorStore: jgdoorStore()
    }
  },
  props: {
    modelValue: String
  },
  computed: {
    bindValue: {
      get() {
        return this.modelValue
      },
      set(v) {
        this.$emit('update:modelValue', v)
      }
    }
  },
  data() {
    return {
      options: [],
      props: {
        emitPath: false,
        value: 'value',
        label: 'label'
      }
    }
  },
  methods: {
    onChange(){
      this.$emit('change', this.bindValue)
    }
  },
  created() {
    this.jgdoorStore.getXueyuanClassifyOptions()
  }

}
</script>