<template>
  <div class="upload-video-list-ex">
    <upload-video v-model="modelVideo" @upload="onUpload" @clear="this.modelImg = ''">
      <template #before>
        <upload-image style="width: 100%;margin-right: 10px;" v-model="modelImg" btn-name="缩略图"/>
      </template>
    </upload-video>
  </div>
</template>

<script>
import UploadVideo from './upload-video.vue'
import UploadImage from './upload-image.vue'
import {base64ToFile, getVideoposter} from "@/views/jgdoor/libs/tools";
import {platformsettingsUploadPlatformImg} from "@/api/api";


export default {
  name: "uploadVideoEx",
  components: {UploadImage, UploadVideo},
  data() {
    return {}
  },
  props: {
    img: {
      type: String,
      default: ""
    },
    video: {
      type: String,
      default: () => ""
    }
  },
  computed: {
    modelImg: {
      set(v) {
        this.$emit('update:img', v)
      },
      get() {
        return this.img
      }
    },
    modelVideo: {
      set(v) {
        this.$emit('update:video', v)
      },
      get() {
        return this.video
      }
    }
  },
  methods: {
    onUpload(url) {
      getVideoposter(url).then((base64Image) => {
        const formData = new FormData()
        formData.file = base64ToFile(base64Image, 'im.jpeg')
        platformsettingsUploadPlatformImg(formData).then(({data: {data}, code}) => {
          if (code === 2000) {
            this.modelImg = data[0]
          } else {
            this.$message.error("视频缩略图上传失败")
          }
        })
      })
    }
  },
}
</script>

<style lang="scss" scoped>

</style>