<template>
  <div class="z-upload-image">
    <div class="from">
      <el-input class="z-input" :disabled="disabledInput" clearable v-model="value">
        <template #append>
          <el-button v-if="!value" icon="Hide"></el-button>
          <el-popover v-else :hide-after="100" placement="left" :width="200" trigger="hover">
            <template #reference>
              <el-button icon="View"></el-button>
            </template>
            <template #default>
              <el-image @click="showImage=true" :src="value"></el-image>
            </template>
          </el-popover>
        </template>
      </el-input>
      <el-upload :accept="accept" action="" :disabled="disabled" :show-file-list="false" ref="refUpload" :http-request="uploadRequest" :on-success="uploadSuccess" :before-upload="beforeUpload">
        <span style="display: inline-flex">
          <slot name="default" :loading="loading">
            <el-button :loading="loading" icon="Picture">{{ btnName }}</el-button>
          </slot>
        </span>
      </el-upload>
      <el-image-viewer v-if="showImage" @close="showImage=false" :url-list="[value]"></el-image-viewer>
    </div>
    <div class="view" v-if="thumbnail && value">
      <el-image class="view-img" :src="value" :preview-src-list="[value]"></el-image>
    </div>
  </div>
</template>

<script>
import {platformsettingsUploadPlatformImg} from "@/api/api";

export default {
  name: "baseUploadImage",
  data() {
    return {
      loading: false,
      showImage: false,
    }
  },
  props: {
    btnName: {
      type: String,
      default: '选择图片'
    },
    accept: {
      // 类型说明
      // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#unique_file_type_specifiers
      type: String,
      default: "image/*"
    },
    modelValue: {
      type: String,
      default: ""
    },
    disabledInput: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    thumbnail: {
      type: Boolean,
      default: false,
    },
    path: [String],
  },
  computed: {
    value: {
      set(v) {
        this.$emit('update:modelValue', v); // visible改变时同步父组件modelValue的值
      },
      get() {
        return this.modelValue
      }
    }
  },
  methods: {
    beforeUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJPG) {
        this.$message.error('图片只能是 JPG/PNG 格式!');
        return false
      }
      return isJPG;
    },
    async uploadRequest(param) {
      this.loading = true
      if(this.path){
        param.path = this.path
      }

      let obj = await platformsettingsUploadPlatformImg(param)
      if (obj.code === 2000) {
        let res = ''
        if (obj.data.data[0].indexOf("://") >= 0) {
          res = obj.data.data[0]

        } else {
          res = url.split('/api')[0] + obj.data.data[0]
        }
        this.value = res
      } else {
        this.$message.warning(res.msg)
      }
      this.loading = false
    },
    uploadSuccess() {
      this.$refs.refUpload.clearFiles()
    }
  },
}
</script>

<style lang="scss" scoped>
.z-upload-image {

  .from {
    display: flex;
    justify-content: center;
    align-items: center;

    .z-input {
      flex: 1 1 auto;
      margin-right: 10px;
    }
  }

  .view-img {
    margin-top: 5px;
    border: 1px solid #ddd;
    padding: 5px;
    height: 150px;
    width: auto;
    object-fit: cover;
  }
}

</style>