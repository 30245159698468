import {ajaxGet, ajaxDelete, ajaxPut, ajaxPost} from "@/api/request";
import {defineStore} from 'pinia'
import XEUtils from 'xe-utils'

function TreeToArray(data){
    let results = []
    data.forEach(item=>{
        const children = item.children
        if(children){
            results = results.concat(TreeToArray(children))
        }else{
            results.push(item)
        }
    })
    return results
}

export const jgdoorStore = defineStore('jgdoor', {
    state: () => {
        return {
            slideshowClassifyOptions: [], chanpinClassifyOptions: [], chanpinClassifyList: [], cityOption: [], cityList: [], xueyuanClassifyOptions: [],
            deptOptions: [], deptTree: []
        }
    }, getters: {
        setDeptOptions(state) {
            return (data) => {
                state.deptTree = data
                state.deptOptions = TreeToArray(data)
            }
        }
    }, actions: {
        getDeptOptions() {
            if (this.deptOptions.length === 0) {
                ajaxGet({
                    url: 'system/dept/dept_tree2/'
                }).then(({code, msg, data}) => {
                    this.deptTree = data.data
                    this.deptOptions = TreeToArray(data.data)
                })
            }
        },
        getSlideshowClassifyOptions() {
            if (this.slideshowClassifyOptions.length === 0) {
                ajaxGet({
                    url: 'jgdoor/base/slideshow/classify/', params: {}
                }).then(({data: data}) => {
                    this.slideshowClassifyOptions = data
                })
            }
        },
        getXueyuanClassifyOptions() {
            if (this.xueyuanClassifyOptions.length === 0) {
                ajaxGet({
                    url: 'jgdoor/base/xueyuanList/classify/', params: {}
                }).then(({data: data}) => {
                    this.xueyuanClassifyOptions = data
                })
            }
        }, getChanpinClassifyOptions() {
            ajaxGet({
                url: 'jgdoor/base/chanpinClassify/?limit=999999', params: {}
            }).then(({data: {data}}) => {
                this.chanpinClassifyOptions = XEUtils.toArrayTree(data, {
                    strict: true, key: 'id', parentKey: 'parent_id',
                })
                this.chanpinClassifyList = data
            })
        }, getCityOptions() {
            ajaxGet({
                url: 'jgdoor/base/city/?limit=999999', params: {}
            }).then(({data: {data}}) => {
                this.cityOption = XEUtils.toArrayTree(data, {
                    strict: true, key: 'id', parentKey: 'parent_id',
                })
                this.cityList = data
            })
        }
    },
})