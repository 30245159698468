<template>
  <vxe-modal ref="dialog" v-if="visible" title="添加" :show-footer="true" width="90%" height="95vh" min-width="800px" v-model="visible">
    <template #default>
      <el-form ref="zForm" :model="formData" label-width="120px" :rules="rules">
        <el-form-item v-if="is_super" label="全国活动" placeholder="请选择" prop="is_nationwide">
          <el-switch v-model="formData.is_nationwide"/>
        </el-form-item>
        <el-form-item v-if="!formData.is_nationwide" label="城市" placeholder="请选择" prop="dept_belong_id">
          <z-dept v-model="formData.dept_belong_id"></z-dept>
        </el-form-item>
        <el-form-item label="标题" placeholder="请输入" prop="title">
          <el-input v-model="formData.title"></el-input>
        </el-form-item>
        <el-form-item label="描述" placeholder="请输入" prop="small">
          <el-input type="textarea" :rows="3" v-model="formData.small"></el-input>
        </el-form-item>
        <el-form-item label="范围" placeholder="请输入" prop="range">
          <el-input v-model="formData.range"></el-input>
        </el-form-item>
        <el-form-item label="地址" placeholder="请输入" prop="addr">
          <el-input v-model="formData.addr"></el-input>
        </el-form-item>
        <el-form-item label="缩略图" placeholder="请选择" prop="img">
          <upload-image :thumbnail="false" v-model="formData.img"/>
        </el-form-item>
        <el-form-item label="详情" placeholder="请输入" prop="content">
          <TEditor v-model="formData.content"/>
        </el-form-item>
<!--        <el-form-item label="激活" placeholder="请选择" prop="show">-->
<!--          <el-switch v-model="formData.show"></el-switch>-->
<!--        </el-form-item>-->
        <el-form-item label="排序">
          <el-input type="number" style="width: 120px" v-model="formData.rank"/>
        </el-form-item>
      </el-form>
    </template>

    <template #footer>
      <div class="el-dialog--center">
        <vxe-button status="primary" :loading="loading" @click="onSubmit">保存</vxe-button>
      </div>
    </template>
  </vxe-modal>
</template>
<script>
import UploadImage from '@/views/jgdoor/components/upload-image.vue'
import TEditor from '@/components/TEditor'
import ZDept from "@/views/jgdoor/components/dept.vue";
import {ajaxGet, ajaxDelete, ajaxPut, ajaxPost} from "@/api/request";
import {getIsSuper} from "@/views/jgdoor/api";

export default {
  components: {ZDept, UploadImage, TEditor},
  emits: ['update'],
  data() {
    return {
      loading: false,
      visible: false,
      is_super: false,
      formData: {
        is_nationwide: false,
        dept_belong_id: undefined,
        title: '',
        small: '',
        range: '',
        addr: '',
        img: '',
        content: '',
        show: true,
        rank: 100,
      },
      rules: {
        is_nationwide: [{required: true, message: '此项不能为空', trigger: 'blur'},],
        dept_belong_id: [{required: true, message: '此项不能为空', trigger: 'blur'},],
        title: [{required: true, message: '此项不能为空', trigger: 'blur'},],
        small: [{required: true, message: '此项不能为空', trigger: 'blur'},],
        range: [{required: true, message: '此项不能为空', trigger: 'blur'},],
        addr: [{required: true, message: '此项不能为空', trigger: 'blur'},],
        img: [{required: true, message: '此项不能为空', trigger: 'blur'},],
        content: [{required: true, message: '此项不能为空', trigger: 'blur'},],
      },

      submitFunc: undefined,
      mode: undefined,
    }
  },
  computed: {},
  methods: {
    show(data, {onSubmit, onChange} = {}) {
      // 初始化数据
      Object.assign(this.$data, this.$options.data())
      Object.assign(this.formData, data)
      getIsSuper().then(({data}) => {
        this.is_super = data
      })

      this.visible = true
      if (onSubmit) {
        this.mode = 'add'
        this.submitFunc = onSubmit
      } else {
        this.mode = 'create'
        this.submitFunc = onChange
      }
    },
    onSubmit() {
      if (this.formData.dept_belong_id === 'all') {
        this.formData.dept_belong_id = ''
      }

      this.$refs.zForm.validate((valid) => {
        if (valid) {
          if (this.formData.is_nationwide) {
            this.formData.dept_belong_id = 'all'
          }
          this.loading = true
          this.submitFunc(this.formData, {
            success: () => {
              this.visible = false
              this.$emit('update')
              return true
            }
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>