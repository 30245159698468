<template>
  <z-container>
    <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" status-icon label-width="140px" class="block-100">
      <el-tabs tab-position="top" type="border-card" class="block-100 flex-tabs">
        <el-tab-pane label="首页">
          <el-form-item label="视频播放密码" prop="video_password">
            <el-input v-model="ruleForm.video_password" />
          </el-form-item>
          <el-form-item label="视频号ID" prop="wechat_video_use_id">
            <el-input v-model="ruleForm.wechat_video_use_id" />
          </el-form-item>
          <el-form-item label="微信小程序二维码" prop="qr_code_applet_wechat">
            <z-upload-image v-model="ruleForm.qr_code_applet_wechat"/>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="关于">
          <el-form-item label="公司名称" prop="company_name">
            <el-input v-model="ruleForm.company_name"></el-input>
          </el-form-item>
          <el-form-item label="坐标" prop="longitude">
            <ZCoord v-model:longitude="ruleForm.longitude" v-model:latitude="ruleForm.latitude"></ZCoord>
          </el-form-item>
          <el-form-item label="地址" prop="addr">
            <el-input v-model="ruleForm.addr"></el-input>
          </el-form-item>
          <el-form-item label="展厅地址" prop="zt_addr">
            <el-input v-model="ruleForm.zt_addr"></el-input>
          </el-form-item>
          <el-form-item label="电话1" prop="phone1">
            <el-input v-model="ruleForm.phone1"></el-input>
          </el-form-item>
          <el-form-item label="电话2" prop="phone2">
            <el-input v-model="ruleForm.phone2"></el-input>
          </el-form-item>
          <el-form-item label="电话3" prop="phone3">
            <el-input v-model="ruleForm.phone3"></el-input>
          </el-form-item>
          <el-form-item label="二维码1" prop="qr_code1">
            <z-upload-image v-model="ruleForm.qr_code1"/>
          </el-form-item>
          <el-form-item label="二维码2" prop="qr_code2">
            <z-upload-image v-model="ruleForm.qr_code2"/>
          </el-form-item>
          <el-form-item label="关于铿固" prop="about">
            <TEditor v-model="ruleForm.about"></TEditor>
          </el-form-item>
        </el-tab-pane>

        <!-- <el-tab-pane label="产品详情">
          <el-form-item label="产品详情" prop="chanpin_xiangqing_imgs">
            <z-upload-image-list thumbnail width="300px" v-model="ruleForm.chanpin_xiangqing_imgs"/>
          </el-form-item>
        </el-tab-pane>-->
      </el-tabs>
    </el-form>

    <template #button>
      <el-button type="primary" @click="submitForm">保存</el-button>
    </template>
  </z-container>
</template>
<script>
import ZContainer from '@/views/jgdoor/components/container-page.vue'
import ZUploadImage from '@/views/jgdoor/components/upload-image.vue'
import ZUploadImageList from '@/views/jgdoor/components/upload-image-list.vue'
import ZCoord from '@/views/jgdoor/components/coord.vue'
import TEditor from '@/components/TEditor'
import {ajaxPut, ajaxGet} from "@/api/request";

export default {
  components: {ZContainer, ZUploadImage, ZUploadImageList, TEditor, ZCoord},
  name: 'baseHome',
  data() {
    return {
      ruleForm: {
        video_password: "",
        wechat_video_use_id: '',
        qr_code_applet_wechat: '',

        company_name: "",
        longitude: undefined,
        latitude: undefined,
        addr: "",
        zt_addr: "",
        phone1: "",
        phone2: "",
        phone3: "",
        qr_code1: "",
        qr_code2: "",
        about: "",

        chanpin_xiangqing_imgs: []
      },
      rules: {
        video_password: [{required: true, message: '此项不能为空', trigger: 'blur'}],
        wechat_video_use_id: [{required: true, message: '此项不能为空', trigger: 'blur'}],
        qr_code_applet_wechat: [{required: true, message: '此项不能为空', trigger: 'blur'}],

        company_name: [{required: true, message: '此项不能为空', trigger: 'blur'}],
        longitude: [{required: true, message: '此项不能为空', trigger: 'blur'}],
        latitude: [{required: true, message: '此项不能为空', trigger: 'blur'}],
        addr: [{required: true, message: '此项不能为空', trigger: 'blur'}],
        zt_addr: [{required: true, message: '此项不能为空', trigger: 'blur'}],
        phone1: [{required: true, message: '此项不能为空', trigger: 'blur'}],
        phone2: [{required: true, message: '此项不能为空', trigger: 'blur'}],
        phone3: [{required: true, message: '此项不能为空', trigger: 'blur'}],
        about: [{required: true, message: '此项不能为空', trigger: 'blur'}],

        chanpin_xiangqing_imgs: [{required: true, message: '此项不能为空', trigger: 'blur'}],

      }
    }
  },
  methods: {
    getData() {
      ajaxGet({
        url: 'jgdoor/base/setting/0/',
      }).then(({data: {data}, msg}) => {
        Object.assign(this.ruleForm, data)
      })
    },
    submitForm() {
      const formEl = this.$refs.ruleFormRef
      formEl.validate((valid, fields) => {
        if (valid) {
          ajaxPut({
            url: 'jgdoor/base/setting/',
            params: this.ruleForm
          }).then(({data, msg}) => {
            this.$message.success(msg)
          })
        } else {
          this.$message.warning("有内容不符合保存要求，请检查...")
          return false
        }
      })
    }
  }, created() {
    this.getData()
  }
}
</script>