<template>
  <z-container-table>
    <template #header>
      <el-form inline :model="queryParams" label-position="left">
        <el-form-item>
          <el-input v-model="queryParams.search" clearable></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button icon="Search" type="primary" @click="MixinFilter"></el-button>
        </el-form-item>

        <el-form-item style="float:right;">
          <el-button @click="$refs.refEdit.show({},{onSubmit:MixinRowCreate})">添加</el-button>
        </el-form-item>
      </el-form>
    </template>
    <template #table>
      <vxe-grid v-bind="zGridConfig" ref="zGrid">
        <template #operate="{ row }">
          <el-button type="primary" link :loading="row.loading" @click="$refs.refEdit.show(row,{onChange:MixinRowSave})">编辑</el-button>
          <el-popconfirm confirm-button-text="是" cancel-button-text="否" title="是否删除?" :hide-after="0" @confirm="MixinRowDelete(row)">
            <template #reference>
              <el-button type="danger" link :loading="row.loading">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </vxe-grid>
    </template>
    <template #footer>
      <Pagination v-bind:child-msg="queryParams" @callFather="MixinFilter"></Pagination>
    </template>
    <template #dialog>
      <z-edit ref="refEdit" @update="MixinFilter"/>
    </template>
  </z-container-table>
</template>
<script>
import ZContainerTable from '@/views/jgdoor/components/container-table.vue'
import BaseTable from '@/views/jgdoor/mixins/table'
import Pagination from "@/components/Pagination.vue";
import ZEdit from './edit.vue'

export default {
  name: 'pinpaiFzls',
  mixins: [BaseTable],
  components: {Pagination, ZContainerTable, ZEdit},
  data() {
    return {
      urlPrefix: 'jgdoor/base/fzls/',

      queryParams: {
        search: '',

        page: 1,
        limit: 20,
        total: 0
      },
      zGridConfig: {
        data: [],
        height: 'auto',
        align: 'left',
        columns: [
          {field: 'rank', width: 120, title: '排序',},
          {field: 'year', width: 220, title: '年份'},
          {field: 'title', minWidth: 220, title: '标题'},
          {title: '操作', width: 140, align: 'center', slots: {default: 'operate'}}
        ]
      }
    }
  },
  mounted() {
    this.MixinFilter()
  }
}
</script>