<template>
  <vxe-modal ref="dialog" title="添加" v-if="visible" :show-footer="true" width="60%" min-width="800px" v-model="visible">
    <template #default>
      <el-form ref="zForm" :model="formData" label-width="120px" :rules="rules">
        <el-form-item label="年份" placeholder="请输入" prop="year">
          <el-input v-model="formData.year"></el-input>
        </el-form-item>
        <el-form-item label="标题" placeholder="请输入" prop="title">
          <el-input v-model="formData.title"></el-input>
        </el-form-item>
        <el-form-item label="说明" placeholder="请输入" prop="small">
          <TEditor v-model="formData.small" />
        </el-form-item>
        <el-form-item label="排序">
          <el-input type="number" style="width: 120px" v-model="formData.rank"/>
        </el-form-item>
      </el-form>
    </template>

    <template #footer>
      <div class="el-dialog--center">
        <vxe-button status="primary" :loading="loading" @click="onSubmit">保存</vxe-button>
      </div>
    </template>
  </vxe-modal>
</template>
<script>
import UploadImage from '@/views/jgdoor/components/upload-image.vue'
import UploadVideoEx from '@/views/jgdoor/components/upload-video-ex.vue'
import TEditor from '@/components/TEditor'

export default {
  components: {UploadImage, UploadVideoEx, TEditor},
  emits: ['update'],
  data() {
    return {
      loading: false,
      visible: false,
      formData: {
        year: '',
        title: '',
        small: '',
        rank: 100,
      },
      rules: {
        year: [{required: true, message: '此项不能为空', trigger: 'blur'},],
        title: [{required: true, message: '此项不能为空', trigger: 'blur'},],
        small: [{required: true, message: '此项不能为空', trigger: 'blur'},],
      },

      submitFunc: undefined,
      mode: undefined,
    }
  },
  computed: {},
  methods: {
    show(data, {onSubmit, onChange} = {}) {
      // 初始化数据
      Object.assign(this.$data, this.$options.data())
      Object.assign(this.formData, data)

      this.visible = true
      if (onSubmit) {
        this.mode = 'add'
        this.submitFunc = onSubmit
      } else {
        this.mode = 'create'
        this.submitFunc = onChange
      }
    },
    onSubmit() {
      this.$refs.zForm.validate((valid) => {
        if (valid) {
          this.loading = true
          this.submitFunc(this.formData, {
            success: () => {
              this.visible = false
              this.$emit('update')
              return true
            }
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>