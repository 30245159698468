<template>
  <div class="z-upload-image-list">
    <div class="from">
      <el-upload :accept="accept" list-type="picture" multiple action="" :disabled="disabled" :show-file-list="false" ref="refUpload" :http-request="uploadRequest" :on-success="uploadSuccess" :before-upload="beforeUpload">
        <el-button :loading="loading" icon="Picture">选择图片</el-button>
      </el-upload>
      <span v-if="valueList.length > 0" class="from-small">已选择 {{valueList.length}} 张图片（图片可拖动排序）</span>
      <el-image-viewer v-if="viewer.show" :initial-index="viewer.index" @close="viewer.show=false" :url-list="valueList"></el-image-viewer>
    </div>
    <div class="view" v-if="thumbnail && valueList.length > 0">
      <vue-draggable :list="valueList" item-key="id">
        <template #item="{ element, index }">
          <div class="view-item">
            <img class="view-img" :src="element">
            <div class="view-hover">
              <View class="view-icon" @click="showImg(index, element)"/>
              <Delete class="view-icon" @click="removeImg(index)"/>
            </div>
          </div>
        </template>
      </vue-draggable>
    </div>
  </div>
</template>

<script>
import {platformsettingsUploadPlatformImg} from "@/api/api";
import {Delete, Pointer, View} from "@element-plus/icons-vue";
import VueDraggable from "vuedraggable"

export default {
  name: "baseUploadImageList",
  components: {Pointer, Delete, View, VueDraggable},
  data() {
    return {
      loading: false,
      viewer: {
        show: false,
        src: ''
      }
    }
  },
  props: {
    accept: {
      // 类型说明
      // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#unique_file_type_specifiers
      type: String,
      default: "image/*"
    },
    modelValue: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    thumbnail: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    valueList: {
      set(v) {
        this.$emit('update:modelValue', v); // visible改变时同步父组件modelValue的值
      },
      get() {
        return this.modelValue
      }
    }
  },
  methods: {
    showImg(index, src) {
      this.viewer.src = src
      this.viewer.index = index
      this.viewer.show = true
    },
    removeImg(index) {
      this.valueList.splice(index, 1);
    },
    beforeUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJPG) {
        this.$message.error('图片只能是 JPG/PNG 格式!');
        return false
      }
      return isJPG;
    },
    async uploadRequest(param) {
      this.loading = true
      let obj = await platformsettingsUploadPlatformImg(param)
      if (obj.code === 2000) {
        let res = ''
        if (obj.data.data[0].indexOf("://") >= 0) {
          res = obj.data.data[0]

        } else {
          res = url.split('/api')[0] + obj.data.data[0]
        }
        this.valueList.push(res)
      } else {
        this.$message.warning(res.msg)
      }
      this.loading = false
    },
    uploadSuccess() {
      this.$refs.refUpload.clearFiles()
    }
  },
}
</script>

<style lang="scss" scoped>
.z-upload-image-list {
  width: 100%;

  .from {
    display: flex;
    //justify-content: center;
    align-items: center;
  }
  .from-small{
    margin-left: 15px;
    color: #888;
  }
  .view {
    .view-item {
      user-select: none;
      margin-top: 5px;
      position: relative;
      display: inline-block;

      &:hover {
        .view-hover {
          display: flex;
        }
      }

      & + .view-item {
        margin-left: 15px;
      }
    }

    .view-img {
      border: 1px solid #ddd;
      padding: 5px;
      height: 150px;
      width: auto;
      object-fit: cover;
    }

    .view-hover {
      display: none;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      justify-content: center;
      align-items: center;
      background: rgba(3, 3, 3, 0.5);
    }

    .view-icon {
      color: #fff;
      font-weight: bold;
      width: 20px;
      height: 20px;
      margin: 10px;
      cursor: pointer;

      &:hover {
        color: #ddd;
      }
    }
  }
}

</style>