<template>
  <vxe-modal ref="dialog" v-if="visible" title="添加" :show-footer="true" width="950px" v-model="visible">
    <template #default>
      <el-row>
        <el-col :span="16">
          <el-form ref="zForm" :model="formData" label-width="120px" :rules="rules">
            <el-form-item label="城市"  v-show="hasPermission(this.$route.name,'Create')" placeholder="请选择" prop="dept_belong_id">
              <z-dept v-model="formData.dept_belong_id"></z-dept>
            </el-form-item>
            <el-form-item label="头像" placeholder="请选择" prop="portrait">
              <UploadImage thumbnail v-model="formData.portrait" path="portrait"/>
            </el-form-item>
            <el-form-item label="门店名称" placeholder="请输入" prop="name">
              <el-input v-model="formData.name"></el-input>
            </el-form-item>
            <el-form-item label="地址" placeholder="请输入" prop="addr">
              <el-input v-model="formData.addr"></el-input>
            </el-form-item>
            <el-row>
              <el-col :span="12">
                <el-form-item label="联系人" placeholder="请输入" prop="contact">
                  <el-input v-model="formData.contact"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="英文" placeholder="请输入" prop="contact_en">
                  <el-input v-model="formData.contact_en"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="电话" placeholder="请输入" prop="phone">
                  <el-input v-model="formData.phone"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="微信" placeholder="请输入" prop="wechat">
                  <el-input v-model="formData.wechat"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="坐标" placeholder="请输入" prop="latitude">
              <ZCoord v-model:longitude="formData.longitude" v-model:latitude="formData.latitude"/>
              <div><a href="https://lbs.qq.com/getPoint/" target="_blank">腾讯坐标拾取</a></div>
            </el-form-item>
            <el-form-item label="图片" placeholder="请选择" prop="img_list">
              <upload-image-list thumbnail v-model="formData.img_list"/>
            </el-form-item>
            <el-form-item label="名片二维码" placeholder="请选择" prop="qrcode">
              <el-input v-model="formData.qrcode" disabled/>
            </el-form-item>
            <el-row>
              <el-col :span="12">
                <el-form-item label="排序">
                  <el-input type="number" style="width: 120px" v-model="formData.rank"/>
                </el-form-item>
              </el-col>
              <el-col :span="12" >
                <template v-if="hasPermission(this.$route.name,'Status')">
                  <el-switch v-model="formData.show" inline-prompt style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" active-text="显示" inactive-text="隐藏"></el-switch>
                </template>
                <template v-else>
                  <el-tag type="success" v-if="formData.show">显示</el-tag>
                  <el-tag type="info" v-else>隐藏</el-tag>
                </template>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
        <el-col :span="8">
          <div id="codeImg" ref="codeImg">
            <div class="qrcode">
              <img class="qrcode-bg" crossorigin="anonymous" src="/static/codeBg2.png" alt="">
<!--              <div  class="qrcode-code">-->
<!--                <VueQRCodeComponent :text="qrcodeUrl" />-->
<!--              </div>-->
              <div class="qrcode-content">
                <div class="title">
                  <div class="title-l">
                    <div class="b">{{ formData.name }}</div>
                    <div>
                      <div class="t">{{ formData.contact }}</div>
                      <div class="t">{{ formData.contact_en }}</div>
                    </div>
                  </div>
                  <div class="title-r">
                    <img :src="formData.portrait" alt="">
                  </div>
                </div>
                <div class="info">
                  <div class="info-l">
                    <div><b>微信：</b>{{ formData.wechat }}</div>
                    <div><b>电话：</b>{{ formData.phone }}</div>
                    <div><b>地址：</b>{{ formData.addr }}</div>
                  </div>
                  <div class="info-r">
                    <VueQRCodeComponent :text="qrcodeUrl" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </template>

    <template #footer>
      <div class="el-dialog--center">
        <vxe-button status="primary" :loading="loading" @click="onSubmit">保存</vxe-button>
      </div>
    </template>
  </vxe-modal>
</template>
<script>
import UploadImageList from '@/views/jgdoor/components/upload-image-list.vue'
import UploadImage from '@/views/jgdoor/components/upload-image.vue'
import TEditor from '@/components/TEditor'
import ZCoord from '@/views/jgdoor/components/coord.vue'
import ZDept from '@/views/jgdoor/components/dept.vue'
import VueQRCodeComponent from 'vue-qrcode-component'
import html2Canvas from 'html2canvas';
import {platformsettingsUploadPlatformImg} from "../../../../api/api";
import {jgdoorStore} from "@/store/jgdoor";
// import pinyin from "pinyin";

//url转base64
const imageUrlToBase64 = (imageUrl, success)=> {
  //一定要设置为let，不然图片不显示
  let image = new Image();
  //解决跨域问题
  image.setAttribute('crossOrigin', 'anonymous');
  image.src = imageUrl
  //image.onload为异步加载
  image.onload = () => {
    var canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;
    var context = canvas.getContext('2d');
    context.drawImage(image, 0, 0, image.width, image.height);
    var quality = 0.8;
    //这里的dataurl就是base64类型
    var dataURL = canvas.toDataURL("image/jpeg", quality);//使用toDataUrl将图片转换成jpeg的格式,不要把图片压缩成png，因为压缩成png后base64的字符串可能比不转换前的长！
    success(dataURL)
  }
}
// base64转化为file文件
const base64ToFile = (urlData, name) => {
  const arr = urlData.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bytes = atob(arr[1]);
  let n = bytes.length;
  const ia = new Uint8Array(n);
  while (n--) {
    ia[n] = bytes.charCodeAt(n);
  }
  return new File([ia], name || 'jpeg', { type: mime });
};

export default {
  components: {UploadImageList, TEditor, ZCoord, ZDept, VueQRCodeComponent, UploadImage},
  emits: ['update'],
  setup() {
    return {
      jgdoorStore: jgdoorStore()
    }
  },
  data() {
    return {
      loading: false,
      visible: false,
      formData: {
        dept_belong_id: undefined,
        addr: '',
        name: '',
        longitude: '',
        latitude: '',

        portrait: '/static/defaultCarLogo.png',
        contact: '',
        contact_en: '',
        phone: '',
        wechat: '',
        img: '',
        img_list: [],
        qrcode: '',
        show: false,
        rank: 100,
      },
      submitFunc: undefined,
      mode: undefined,
    }
  },
  // watch: {
  //   'formData.contact': function(nv){
  //     this.formData.contact_en = pinyin(nv,{style: pinyin.STYLE_FIRST_LETTER})
  //   }
  // },
  computed: {
    rules(){
      const rules = {
        dept_belong_id: [{required: true, message: '此项不能为空', trigger: 'blur'},],
        addr: [{required: true, message: '此项不能为空', trigger: 'blur'},],
        name: [{required: true, message: '此项不能为空', trigger: 'blur'},],
        contact: [{required: true, message: '此项不能为空', trigger: 'blur'},],
        phone: [{required: true, message: '此项不能为空', trigger: 'blur'},],
        wechat: [{required: true, message: '此项不能为空', trigger: 'blur'},],
        img: [{required: true, message: '此项不能为空', trigger: 'blur'},],
        portrait: [{required: true, message: '此项不能为空', trigger: 'blur'},],
        contact_en: [{required: true, message: '此项不能为空', trigger: 'blur'},],
      }

      if(this.formData.show){
        rules['latitude'] = [{required: true, message: '此项不能为空', trigger: 'blur'},]
        rules['longitude'] = [{required: true, message: '此项不能为空', trigger: 'blur'},]
        rules['img_list'] = [{required: true, message: '此项不能为空', trigger: 'blur'},]
      }
      return rules
    },
    qrcodeUrl() {
      const baseUrl = 'https://applet.jgdoor.com/mp/';
      // const district = this.deptParentDict[this.formData.dept_belong_id];
      // const city = this.deptParentDict[district.parent_id];
      // const province = this.deptParentDict[city.parent_id];
      const query = {
        dept_belong_id: this.formData.dept_belong_id,
        contact: this.formData.contact,
      };
      return `${baseUrl}?${new URLSearchParams(query).toString()}`;
    }
  },
  methods: {
    show(data, {onSubmit, onChange} = {}) {
      // 初始化数据
      Object.assign(this.$data, this.$options.data())
      Object.assign(this.formData, data)

      this.visible = true
      if (onSubmit) {
        this.mode = 'add'
        this.submitFunc = onSubmit
      } else {
        this.mode = 'create'
        this.submitFunc = onChange
      }
    },
    onSubmit() {
      this.$refs.zForm.validate((valid) => {
        if (valid) {
          if (this.formData.img_list.length === 0 && this.formData.show) {
            this.$message.error("请选择图片")
            return false
          } else {
            this.formData.img = this.formData.img_list[0]
          }

          this.loading = true
          const elCodeImg = this.$refs.codeImg.cloneNode(true)
          elCodeImg.style.cssText = "font-size: 35px;"
          const elBox = document.createElement("div")
          elBox.style.cssText = "width: 790px;height:auto;position: fixed;top: -99999;top: -7000%;left: 0;"
          elBox.append(elCodeImg)
          document.body.append(elBox)

          html2Canvas(elCodeImg).then(canvas=>{
            const jpeg = canvas.toDataURL('image/jpeg', 1.0);
            elBox.remove()

            platformsettingsUploadPlatformImg({
              file: base64ToFile(jpeg, 'code.jpg')
            }).then(obj=>{
              if (obj.code === 2000) {
                if (obj.data.data[0].indexOf("://") >= 0) {
                  this.formData.qrcode = obj.data.data[0]
                } else {
                  this.formData.qrcode = url.split('/api')[0] + obj.data.data[0]
                }

                this.submitFunc(this.formData, {
                  success: () => {
                    this.visible = false
                    this.$emit('update')
                    return true
                  }
                }).finally(() => {
                  this.loading = false
                })
              } else {
                this.$message.warning(obj.msg)
                this.loading = false
              }
            })
          })

        }
      })
    }
  }
}
</script>

<style lang="scss">
#codeImg{
  overflow: hidden;
  padding: 15px;
  font-size: 12.8px;

  .qrcode{
    position: relative;
    * {
      box-sizing: border-box;
    }
    .qrcode-code{
      > *{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }
      position: absolute;
      transform: translate(-50%, -50%);
      left: 49%;
      top: 40%;
      padding: 21%;
    }
    .qrcode-content{
      position: absolute;
      top: 62%;
      left: 14%;
      width: 72%;

      .title{
        height: 6.3em;
        display: flex;
        flex-direction: row;
        align-items: center;
        line-height: 1.3;
        .title-l{
          font-size: 1.1em;
          flex: 1 1 auto;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        }
        .title-r{
          flex: 0 0 4.6em;
          img{
            width: 4.6em;
            height: 4.6em;
            border-radius: 50% 50%;
            object-fit: cover;
          }
        }
        .b{
          font-size: 1em;
          font-weight: bolder;
        }
        .t{
          font-size: 0.8em;
        }
      }
      .info{
        margin-top: 0.55em;
        font-size: 0.8em;
        display: flex;
        line-height: 1.3;
        .info-l{
          flex: 1 1 auto;
          margin-right: 0.5em;
        }
        .info-r{
          flex: 0 0 6em;
        }
      }
    }

    img{
      width: 100%;
      height: auto;
    }
  }
  .qrcode-bg{
  }
}
</style>