<template>
  <vxe-modal ref="dialog" title="添加" v-if="visible" :show-footer="true" width="90%" height="95vh" min-width="800px" v-model="visible">
    <template #default>
      <el-form ref="zForm" :model="formData" label-width="120px" :rules="rules">
        <el-form-item label="城市" placeholder="请选择" prop="dept_belong_id">
          <z-dept v-model="formData.dept_belong_id"></z-dept>
        </el-form-item>
        <el-form-item label="标题" placeholder="请输入" prop="title">
          <el-input v-model="formData.title"></el-input>
        </el-form-item>
<!--        <el-form-item label="微信视频ID" placeholder="请输入" prop="video_wechat_id">-->
<!--          <el-input v-model="formData.video_wechat_id"></el-input>-->
<!--        </el-form-item>-->
        <el-form-item label="图片/视频" placeholder="请选择" prop="img">
          <upload-video-ex thumbnail v-model:img="formData.img" v-model:video="formData.video"/>
        </el-form-item>
        <el-form-item label="图片列表" placeholder="请选择" prop="img_list">
          <upload-image-list thumbnail v-model="formData.img_list" />
        </el-form-item>
        <el-form-item label="详情" placeholder="请输入" prop="content">
          <TEditor v-model="formData.content" />
        </el-form-item>
        <el-form-item label="精品案例" placeholder="请选择" prop="top" v-if="hasPermission(this.$route.name,'Top')">
          <el-switch v-model="formData.top"></el-switch>
        </el-form-item>
        <el-form-item label="排序">
          <el-input type="number" style="width: 120px" v-model="formData.rank"/>
        </el-form-item>
      </el-form>
    </template>

    <template #footer>
      <div class="el-dialog--center">
        <vxe-button status="primary" :loading="loading" @click="onSubmit">保存</vxe-button>
      </div>
    </template>
  </vxe-modal>
</template>
<script>
import UploadVideoEx from '@/views/jgdoor/components/upload-video-ex.vue'
import UploadImageList from '@/views/jgdoor/components/upload-image-list.vue'
import TEditor from '@/components/TEditor'
import ZDept from "@/views/jgdoor/components/dept.vue";

export default {
  components: {ZDept, UploadVideoEx, TEditor, UploadImageList},
  emits: ['update'],
  data() {
    return {
      loading: false,
      visible: false,
      formData: {
        dept_belong_id: undefined,
        title: '',
        img: '',
        video: '',
        video_wechat_id: '',
        img_list: [],
        content: '',
        top: false,
        rank: 100,
      },
      rules: {
        dept_belong_id: [{required: true, message: '此项不能为空', trigger: 'blur'},],
        title: [{required: true, message: '此项不能为空', trigger: 'blur'},],
        img_list: [{required: true, message: '此项不能为空', trigger: 'blur'},],
        content: [{required: true, message: '此项不能为空', trigger: 'blur'},],
      },

      submitFunc: undefined,
      mode: undefined,
    }
  },
  computed: {},
  methods: {
    show(data, {onSubmit, onChange} = {}) {
      // 初始化数据
      Object.assign(this.$data, this.$options.data())
      Object.assign(this.formData, data)

      this.visible = true
      if (onSubmit) {
        this.mode = 'add'
        this.submitFunc = onSubmit
      } else {
        this.mode = 'create'
        this.submitFunc = onChange
      }
    },
    onSubmit() {
      this.$refs.zForm.validate((valid) => {
        if (valid) {
          this.loading = true
          if(!this.formData.img){
            this.formData.img = this.formData.img_list[0]
          }

          this.submitFunc(this.formData, {
            success: () => {
              this.visible = false
              this.$emit('update')
              return true
            }
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>