import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import './main.scss'

VXETable.setup({
    size: 'small', // 全局尺寸
    // zIndex: 999, // 全局 zIndex 起始值，如果项目的的 z-index 样式值过大时就需要跟随设置更大，避免被遮挡；新版本可以使用 dom-zindex 共享配置
    // version: 0, // 版本号，对于某些带数据缓存的功能有用到，上升版本号可以用于重置数据
    // loadingText: '加载中...', // 全局loading提示内容，如果为null则不显示文本
    table: {
        align: "center",
        border: true,
    },
})

export default (app) => {
    app.use(VXETable)
}