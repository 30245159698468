<template>
  <el-container>
    <el-aside width="500px" style="margin-right: 15px;">
      <TableMonth />
    </el-aside>
    <el-container>
      <TableDay />
    </el-container>
  </el-container>
</template>
<script>
import ZContainerTable from '@/views/jgdoor/components/container-table.vue'
import BaseTable from '@/views/jgdoor/mixins/table'
import Pagination from "@/components/Pagination.vue";
import SlideshowClassify from '@/views/jgdoor/components/slideshow-classify.vue'
import ZDept from "@/views/jgdoor/components/dept.vue";
import dayjs from "dayjs";
import {ajaxGet} from "@/api/request";
import TableDay from './table-day.vue'
import TableMonth from './table-month.vue'

export default {
  name: 'statistics',
  mixins: [BaseTable],
  components: {ZDept, Pagination, ZContainerTable, SlideshowClassify, TableDay, TableMonth},
  data() {
    return {
      urlPrefix: 'jgdoor/base/statistics/',
      countInfo: [],
      queryParams: {
        dept_belong_id: undefined,
        date: dayjs().format('YYYY-MM-DD'),

        page: 1,
        limit: 20,
        total: 0
      },
      zGridConfig: {
        data: [],
        height: 'auto',
        align: 'center',
        headerAlign: 'left',
        columns: [
          {field: 'date', width: 120, title: '日期'},
          {field: 'platform', width: 120, title: '来源'},
          {field: 'sharer', width: 120, title: '分享人'},
          {field: 'dept_belong_id', title: '城市', width: 110, slots: {default: 'dept'}},
          {field: 'ip', minWidth: 220, title: 'IP'},
          {field: 'count', width: 220, title: '浏览量'},
        ]
      }
    }
  },
  methods: {
    onFilter() {
      this.MixinFilter()
      console.log(this.queryParams)
      if (this.queryParams.date) {
        ajaxGet({
          url: 'jgdoor/base/statistics/info/',
          params: {date: this.queryParams.date}
        }).then(({data}) => {
          this.countInfo = data
        })
      }else{
        this.countInfo = []
      }
    }
  },
  mounted() {
    this.onFilter()
  }
}
</script>

<style lang="scss" scoped>
.form {
  display: block;
}
</style>