<template>
  <vxe-modal ref="dialog" title="产品分类" :show-footer="true" height="600px" width="900px" v-model="visible">
    <template #default>
      <z-container-table style="height: 100%">
        <template #header>
          <el-form ref="zForm" :inline="true" :model="queryParams">
            <el-form-item>
              <el-button @click="$refs.refEdit.show({},{onSubmit:MixinRowCreate})">添加</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template #table>
          <vxe-grid v-bind="zGridConfig" ref="zGrid" :data="jgdoorStore.chanpinClassifyList">
            <template #operate="{ row }">
              <el-button type="primary" link :loading="row.loading" @click="$refs.refEdit.show(row,{onChange: this.MixinRowSave})">编辑</el-button>
              <el-popconfirm confirm-button-text="是" cancel-button-text="否" title="是否删除?" :hide-after="0" @confirm="onRemove(row)">
                <template #reference>
                  <el-button type="danger" link :loading="row.loading">删除</el-button>
                </template>
              </el-popconfirm>
            </template>
          </vxe-grid>
        </template>
        <template #dialog>
          <z-edit ref="refEdit"/>
        </template>
      </z-container-table>
    </template>
  </vxe-modal>
</template>
<script>
import ZContainerTable from '@/views/jgdoor/components/container-table.vue'
import BaseTable from '@/views/jgdoor/mixins/table'
import Pagination from "@/components/Pagination.vue";
import ZEdit from "./classifyEdit.vue";
import {jgdoorStore} from "@/store/jgdoor";

export default {
  mixins: [BaseTable],
  components: {Pagination, ZContainerTable, ZEdit},
  setup() {
    return {
      jgdoorStore: jgdoorStore()
    }
  },
  data() {
    return {
      urlPrefix: 'jgdoor/base/chanpinClassify/',
      visible: false,
      formData: {
        parent_id: undefined,
        rank: 100,
        title: '',
        en: '',
        img: ''
      },
      queryParams: {
        page: 1,
        limit: 99999,
        total: 0
      },
      rules: {
        rank: [{required: true, message: '此项不能为空', trigger: 'blur'},],
        title: [{required: true, message: '此项不能为空', trigger: 'blur'},],
      },

      zGridConfig: {
        size: 'mini',
        height: 'auto',
        align: 'left',
        treeConfig: {
          transform: true, rowField: 'id', parentField: 'parent_id', hasChildField: 'has_child'
        },
        columns: [
          {field: 'rank', width: 80, title: '排序'},
          {field: 'title', width: 220, title: '分类名称', treeNode: true},
          {field: 'en', minWidth: 220, title: '英文'},
          // {field: 'small', width: 220, title: '说明'},
          {title: '操作', width: 140, align: 'center', slots: {default: 'operate'}}
        ]
      },
    }
  },
  methods: {
    show() {
      Object.assign(this.$data, this.$options.data())
      this.visible = true
    },
    onCreate() {
      this.$refs.zForm.validate((valid) => {
        if (valid) {
          this.MixinRowCreate(this.formData, {
            success: ({data: {data}}) => {
              this.formData.name = ''
              this.formData.en = ''
              this.formData.rank = 100
              this.formData.parent_id = undefined
              this.jgdoorStore.getChanpinClassifyOptions()
              return false
            }
          })
        }
      })
    },
    onRemove(row) {
      this.MixinRowDelete(row, {
        success: ({msg}) => {
          this.$message.success(msg)
          this.jgdoorStore.getChanpinClassifyOptions()
          return false
        }
      })
    }
  },
  updated() {
  }
}
</script>