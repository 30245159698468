<template>
  <el-cascader v-model="bindValue" :options="jgdoorStore.chanpinClassifyOptions" @change="onChange" clearable :props="props"/>
</template>
<script>
import {ajaxGet} from "@/api/request";
import {jgdoorStore} from "@/store/jgdoor";

export default {
  setup() {
    return {
      jgdoorStore: jgdoorStore()
    }
  },
  props: {
    modelValue: String,
    checkStrictly: {
      type: Boolean,
      default: true
    },
  },
  computed: {
    bindValue: {
      get() {
        return this.modelValue
      },
      set(v) {
        this.$emit('update:modelValue', v)
      }
    },
    props: {
      get() {
        return {
          emitPath: false,
          checkStrictly: this.checkStrictly,
          value: 'id',
          label: 'title'
        }
      }
    }
  },
  data() {
    return {
      options: [],
    }
  },
  methods: {
    onChange(){
      this.$emit('change', this.bindValue)
    }
  },
  created() {
    this.jgdoorStore.getChanpinClassifyOptions()
  }

}
</script>