<template>
  <!--  <el-tree-select v-model="dept" node-key="id" :data="options"-->
  <!--      check-strictly filterable clearable :render-after-expand="false"-->
  <!--      :props="{label:'name',value: 'id'}"-->
  <!--      style="width: 100%" placeholder="请选择"-->
  <!--  />-->
  <div>
    <span v-if="text">{{ deptName }}</span>
    <el-cascader style="display: block;" v-else v-model="dept" placeholder="请选择地区" :options="options" clearable :props="props"/>
  </div>
</template>
<script>
import {apiSystemDept} from "@/api/api";
import XEUtils from "xe-utils";
import {jgdoorStore} from "@/store/jgdoor";

/* Array To Tree */

export default {
  setup() {
    return {
      jgdoorStore: jgdoorStore()
    }
  },
  props: {
    modelValue: String,
    text: {
      type: Boolean,
      default: false
    },
    checkStrictly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    props() {
      return {
        emitPath: false,
        checkStrictly: this.checkStrictly,
        multiple: false,
        value: 'id',
        label: 'name'
      }
    },
    deptName() {
      if (this.dept) {
        const item = this.jgdoorStore.deptOptions.find(item => item.id === this.dept)
        return item ? item.name : ""
      }
    },
    dept: {
      get() {
        return this.modelValue
      },
      set(v) {
        this.$emit('update:modelValue', v)
      }
    },
    options() {
      return this.jgdoorStore.deptTree
    }
  },
  methods: {},
}
</script>